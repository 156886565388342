// Endpoint : https://ddragon.leagueoflegends.com/api/versions.json

export const getVersionClient = async () =>{
    const url = 'https://ddragon.leagueoflegends.com/api/versions.json';
    try{
        const response = await fetch(url);
        if (response.ok) {
            const jsonResponse = await response.json();
            return jsonResponse;
        }
        throw new Error('Request failed')
    }catch(error){
        console.log(error);
    }
} 

export const getSummenerVersion = async (version, langu) =>{
    const url = `https://ddragon.leagueoflegends.com/cdn/${version}/data/${langu}/champion.json`;
    try{
        const response = await fetch(url);
        if (response.ok) {
            const jsonResponse = await response.json();
            return jsonResponse;
        }
        throw new Error('Request failed')
    }catch(error){
        console.log(error);
    }
} 
