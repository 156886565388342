import React, {useState, useEffect} from 'react';
import './App.css';
import {getVersionClient, getSummenerVersion} from './api/lol';
import Card from './components/card'

function App() {

  const [version, setVersion] = useState('');
  const [champions, setChampions] = useState([]);
  const [langu, setLangu] = useState('en_US');

  const [showStat, setShowStat] = useState(false);
  
  useEffect(() => {
    getVersionClient()
      .then(version => {setVersion(version[0])})
  }, [])

  useEffect(() => {

    if (version.length > 0) {
      getSummenerVersion(version, langu)
        .then(data => setChampions(Object.entries(data.data)));
    }

  }, [version, langu]);
  
  const handleShowStat = () =>{
    if (showStat === true) {
      setShowStat(false);
    }else{
      setShowStat(true);
    }
  }

  const handleLang = (e) =>{
    setLangu(e.target.value);
  }

  return (
    <div className="container">

      <div className='row'>
        <div className='col-md-10 p-5 text-center'>
          <h1>Riot Client Version {version}</h1>
        </div>
        <div className='col-md-2 text-center p-5'>
          <button value={'en_US'} onClick={handleLang} className='btn btn-dark m-1'>ENG</button>
          <button value={'de_DE'} onClick={handleLang} className='btn btn-dark m-1'>DEU</button>
        </div>
      </div>

      <div className='row'>
          {champions.map((champion) => (
            <Card champion={champion}/>
          ))}
      </div>
    </div>
  );
}

export default App;
