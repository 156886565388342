import React, {useState, useEffect} from 'react';
import { motion } from 'framer-motion';

function Card({ champion }) {

  const [showStat, setShowStat] = useState(false);
  const [statsChamp, setStatsChamp] = useState([]);

  
  const handleShowStat = () =>{
    setShowStat(true);
  }

  const handleCloseStat = () =>{
    setShowStat(false);
  }

  useEffect(() => {
    setStatsChamp(Object.entries(champion[1]['stats']));
  }, [showStat])


  return (

            <>

              <motion.div whileHover={{ scale: 1.1 }} key={champion[0]} className='col-md-2 border text-center m-3 p-3 border-dark rounded-4 shadow'>
                <p className='fs-4 m-2'>{champion[0]}</p>

                <img onClick={handleShowStat} className='img-fluid rounded-4' src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion[0]}_0.jpg`} alt=""/>

                <p className='m-2'>{champion[1]['title']}</p>
                <hr/>

                <p className='m-2'>{champion[1]['tags'].join()}</p>
                <hr/>


                <div className="progress m-3" role="progressbar" aria-label="Attack" aria-valuenow={champion[1]['info']['attack']* 10} aria-valuemin="0" aria-valuemax="100">
                  <p className='position-absolute z-1 ms-2 text-dark'>Attack</p>
                  <div className="progress-bar bg-danger" style={{width: champion[1]['info']['attack']* 10+'%'}}></div>
                </div>

                <div className="progress m-3" role="progressbar" aria-label="Magic" aria-valuenow={champion[1]['info']['magic']* 10} aria-valuemin="0" aria-valuemax="100">
                  <p className='position-absolute z-1 ms-2 text-dark'>Magic</p>
                  <div className="progress-bar bg-info" style={{width: champion[1]['info']['magic']* 10+'%'}}></div>
                </div>

                <div className="progress m-3" role="progressbar" aria-label="Defense" aria-valuenow={champion[1]['info']['defense']* 10} aria-valuemin="0" aria-valuemax="100">
                  <p className='position-absolute z-1 ms-2 text-dark'>Defense</p>
                  <div className="progress-bar bg-primary" style={{width: champion[1]['info']['defense']* 10+'%'}}></div>
                </div>

                <div className="progress m-3" role="progressbar" aria-label="Difficulty" aria-valuenow={champion[1]['info']['difficulty']* 10} aria-valuemin="0" aria-valuemax="100">
                  <p className='position-absolute z-1 ms-2 text-dark'>Difficulty</p>
                  <div className="progress-bar bg-warning" style={{width: champion[1]['info']['difficulty']* 10+'%'}}></div>
                </div>


              </motion.div>

              {showStat === true ? (
                <motion.div whileHover={{ scale: 1.2 }} key={champion[0]} style={{height: 500, overflow: 'scroll'}} className='card col-md-4 z-1'>
                  <div className='card-header d-flex justify-content-between'>
                    <p>{champion[0]}</p>
                    <button onClick={handleCloseStat} className='btn btn-danger'>X</button>
                  </div>
                  <div className='card-body'>
                    
                    <div className="table-responsive">
                      <table className="table table-primary" >
                        <thead>
                          <tr>
                            <th scope="col">Stats</th>
                            <th scope="col">value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statsChamp.map((stat) => (
                            <tr className="" key={stat[0]}>
                              <td scope="row">{stat[0]}</td>
                              <td>{stat[1]}</td>
                            </tr>
                          ))}
                          <hr className='m-4'/>
                          <p>{champion[1]['blurb']}</p>
                        </tbody>
                      </table>
                    </div>
                    
                  </div>
                </motion.div>
              ) : ('')}
              
            </>

  );
}

export default Card;
